import { css } from "@emotion/css";
import React, { useState, FC, useCallback, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { CloseButton } from "../../atoms/Close";
import { SeeDetailsButton } from "../../atoms/seeDetailsButton";
import { labels } from "../../../utils/ui/tokyoTop";
import { mediaQuery } from "../../../utils/ui";
import useOutsideClick from "../../../utils/hooks/useOutsideClick";

export type Props = {
  closeHandler: () => void;
};
const imgDir = "/img/electricityConnectionModal/";
export const ElectricityConnectionModal: FC<Props> = (props) => {
  const ref = useRef(null);

  useOutsideClick(ref, () => props.closeHandler());
  return (
    <AnimatePresence>
      <motion.div
        key="modal"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={modalBaseStyle}
      >
        <div id="modal" className={mainStyle} ref={ref}>
          <article className={boxStyle}>
            {/* eslint-disable @next/next/no-img-element */}

            <div className={closeStyle}>
              <CloseButton closeModal={props.closeHandler} />
            </div>
            <img
              id="modal-icon"
              src={`${imgDir}/electricityConnection.png?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`}
              alt=""
            />
            <div id="mainText">
              電気使用量の情報連携準備中…
            </div>
            <div id="subText">
              電気使用量の情報の連携手続きを行なっています。初回連携には3日程度かかりますので、しばらくお待ちください。
            </div>
            <div className={doneStyle} id="done">
              <SeeDetailsButton
                title={labels.closeButton}
                onclick={props.closeHandler}
                actionStatus="close"
              />
            </div>
          </article>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export const modalBaseStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100vw;
  height: 100%;
  background-color:rgba(0,0,0,0.5);
`;

const boxStyle = css`
  position: relative;
  width: 355px;
  height: 575px;
  border-radius: 8px;
  background-size: contain;
  background-color: #fff;
  background-repeat: no-repeat;
  padding:39px 22px 40px;
`;

const closeStyle = css`
  position: absolute;
  top: 20px;
  right: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const doneStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
`;

const mainStyle = css`
  text-align: center;
  #modal-icon {
    position: relative;
    display: inline;
    width: 260px;
    height: 200px;
  }
  #mainText {
    width: 260px;
    height: 75px;
    color: var(--txt, #333);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 40px auto 0px;
  }
  #subText {
    width: 312px;
    height: 55px;
    color: var(--txt, #333);
    flex-shrink: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin: 40px auto 0px;
  }
`;