import { getImgSrc, getUrl, ImgKey, UrlKey } from "../../municipality";

export const labels = {
  role: "わが家の環境局長",
  san: "さん",
  addToHomeScreen: "プレゼントにおうぼする",
  closeButton: "とじる",
  appointmentHeaders: ["わが家の環境局長に", "任命されました ", "!!"],
  NowLabel: "ただいま",
  UserCountLabel: "家族ちょうせん中！",
};

const getMainVisual: (key: ImgKey) => MainVisualInfo = (key) => {
  {
    return {
      VisualImg: getImgSrc(key),
      visualAlt: key,
      width: 280,
      height: 300,
    };
  }
};

const getPresentUrl: (key: UrlKey) => string = (key) => {
  return getUrl(key);
};
export const bingoCountInfo: BingoCountInfo[] = [
  {
    bingoCount: 0,
    mainVisual: getMainVisual("Top-Visual00"),
    presentUrl: getPresentUrl("Present-Url00"),
    isHidePresentLink: !getPresentUrl("Present-Url00"),
  },
  {
    bingoCount: 1,
    mainVisual: getMainVisual("Top-Visual01"),
    presentUrl: getPresentUrl("Present-Url01"),
    isHidePresentLink: !getPresentUrl("Present-Url01"),
  },
  {
    bingoCount: 2,
    mainVisual: getMainVisual("Top-Visual02"),
    presentUrl: getPresentUrl("Present-Url02"),
    isHidePresentLink: !getPresentUrl("Present-Url02"),
  },
  {
    bingoCount: 3,
    mainVisual: getMainVisual("Top-Visual03"),
    presentUrl: getPresentUrl("Present-Url03"),
    isHidePresentLink: !getPresentUrl("Present-Url03"),
  },
  {
    bingoCount: 4,
    mainVisual: getMainVisual("Top-Visual04"),
    presentUrl: getPresentUrl("Present-Url04"),
    isHidePresentLink: !getPresentUrl("Present-Url04"),
  },
  {
    bingoCount: 5,
    mainVisual: getMainVisual("Top-Visual05"),
    presentUrl: getPresentUrl("Present-Url05"),
    isHidePresentLink: !getPresentUrl("Present-Url05"),
  },
  {
    bingoCount: 6,
    mainVisual: getMainVisual("Top-Visual06"),
    presentUrl: getPresentUrl("Present-Url06"),
    isHidePresentLink: !getPresentUrl("Present-Url06"),
  },
  {
    bingoCount: 7,
    mainVisual: getMainVisual("Top-Visual07"),
    presentUrl: getPresentUrl("Present-Url07"),
    isHidePresentLink: !getPresentUrl("Present-Url07"),
  },
  {
    bingoCount: 8,
    mainVisual: getMainVisual("Top-Visual08"),
    presentUrl: getPresentUrl("Present-Url08"),
    isHidePresentLink: !getPresentUrl("Present-Url08"),
  },
  {
    bingoCount: 9,
    mainVisual: getMainVisual("Top-Visual09"),
    presentUrl: getPresentUrl("Present-Url09"),
    isHidePresentLink: !getPresentUrl("Present-Url09"),
  },
  {
    bingoCount: 10,
    mainVisual: getMainVisual("Top-Visual10"),
    presentUrl: getPresentUrl("Present-Url10"),
    isHidePresentLink: !getPresentUrl("Present-Url10"),
  },
  {
    bingoCount: 11,
    mainVisual: getMainVisual("Top-Visual11"),
    presentUrl: getPresentUrl("Present-Url11"),
    isHidePresentLink: !getPresentUrl("Present-Url11"),
  },
  {
    bingoCount: 12,
    mainVisual: getMainVisual("Top-Visual12"),
    presentUrl: getPresentUrl("Present-Url12"),
    isHidePresentLink: !getPresentUrl("Present-Url12"),
  },
  {
    bingoCount: 13,
    mainVisual: getMainVisual("Top-Visual13"),
    presentUrl: getPresentUrl("Present-Url13"),
    isHidePresentLink: !getPresentUrl("Present-Url13"),
  },
  {
    bingoCount: 14,
    mainVisual: getMainVisual("Top-Visual14"),
    presentUrl: getPresentUrl("Present-Url14"),
    isHidePresentLink: !getPresentUrl("Present-Url14"),
  },
  {
    bingoCount: 15,
    mainVisual: getMainVisual("Top-Visual15"),
    presentUrl: getPresentUrl("Present-Url15"),
    isHidePresentLink: !getPresentUrl("Present-Url15"),
  },
  {
    bingoCount: 16,
    mainVisual: getMainVisual("Top-Visual16"),
    presentUrl: getPresentUrl("Present-Url16"),
    isHidePresentLink: !getPresentUrl("Present-Url16"),
  },
  {
    bingoCount: 17,
    mainVisual: getMainVisual("Top-Visual17"),
    presentUrl: getPresentUrl("Present-Url17"),
    isHidePresentLink: !getPresentUrl("Present-Url17"),
  },
  {
    bingoCount: 18,
    mainVisual: getMainVisual("Top-Visual18"),
    presentUrl: getPresentUrl("Present-Url18"),
    isHidePresentLink: !getPresentUrl("Present-Url18"),
  },
  {
    bingoCount: 19,
    mainVisual: getMainVisual("Top-Visual19"),
    presentUrl: getPresentUrl("Present-Url19"),
    isHidePresentLink: !getPresentUrl("Present-Url19"),
  },
  {
    bingoCount: 20,
    mainVisual: getMainVisual("Top-Visual20"),
    presentUrl: getPresentUrl("Present-Url20"),
    isHidePresentLink: !getPresentUrl("Present-Url20"),
  },
  {
    bingoCount: 21,
    mainVisual: getMainVisual("Top-Visual21"),
    presentUrl: getPresentUrl("Present-Url21"),
    isHidePresentLink: !getPresentUrl("Present-Url21"),
  },
  {
    bingoCount: 22,
    mainVisual: getMainVisual("Top-Visual22"),
    presentUrl: getPresentUrl("Present-Url22"),
    isHidePresentLink: !getPresentUrl("Present-Url22"),
  },
  {
    bingoCount: 23,
    mainVisual: getMainVisual("Top-Visual23"),
    presentUrl: getPresentUrl("Present-Url23"),
    isHidePresentLink: !getPresentUrl("Present-Url23"),
  },
  {
    bingoCount: 24,
    mainVisual: getMainVisual("Top-Visual24"),
    presentUrl: getPresentUrl("Present-Url24"),
    isHidePresentLink: !getPresentUrl("Present-Url24"),
  },
];

export type BingoCountInfo = {
  bingoCount: number;
  mainVisual: MainVisualInfo;
  presentUrl: string;
  isHidePresentLink?: boolean;
};

type MainVisualInfo = {
  VisualImg: string;
  visualAlt: string;
  width: number;
  height: number;
};
