import { GRAPHQL_TAG_TYPE } from ".";
import { api as generatedApi } from "./generated";

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: [
    GRAPHQL_TAG_TYPE.USER_ACTIONS,
    GRAPHQL_TAG_TYPE.VIEW_NOW,
    GRAPHQL_TAG_TYPE.USERS,
    GRAPHQL_TAG_TYPE.ELECTRICITY_USAGE_PER_USER_DATE,
    GRAPHQL_TAG_TYPE.ELECTRICITY_USAGE_DETAIL,
  ],
  endpoints: {
    GetUserActionsByAuth0UserId_20240227: {
      providesTags: [GRAPHQL_TAG_TYPE.USER_ACTIONS],
    },
    GetActionByAuth0UserIdAndActionId_20240227: {
      providesTags: [GRAPHQL_TAG_TYPE.USER_ACTIONS],
    },
    InsertUserAction_20240227: {
      invalidatesTags: [GRAPHQL_TAG_TYPE.USER_ACTIONS],
    },
    GetNow_20240227: {
      // このタグの名前でキャッシュする
      // 日跨ぎで取りなおすならそのようにロジック書く。
      // 最終チェック時間をlocalで保持しておいて、5時またいだらリセットする
      providesTags: [GRAPHQL_TAG_TYPE.VIEW_NOW],
    },
    BingoGetUserMunicipality_20240227: {
      providesTags: [GRAPHQL_TAG_TYPE.USERS],
    },
    BingoInsertUser_20240227: {
      invalidatesTags: [GRAPHQL_TAG_TYPE.USERS],
    },
    InsertBingoUser_20240227: {
      invalidatesTags: [GRAPHQL_TAG_TYPE.USERS],
    },
    BingoGetUserElectricUsagePerDate_20240227: {
      providesTags: [GRAPHQL_TAG_TYPE.ELECTRICITY_USAGE_PER_USER_DATE],
    },
    BingoGetUserElectricUsageDetail_20240227: {
      providesTags: [GRAPHQL_TAG_TYPE.ELECTRICITY_USAGE_DETAIL],
    },
    BingoGetAreaTemperatureByAreaAndDate_20240227: {
      providesTags: [GRAPHQL_TAG_TYPE.AREA_TEMPERATURE_BY_DATE],
    },
    BingoGetMyData_20240227: {
      providesTags: [GRAPHQL_TAG_TYPE.MY_DATA],
    },
    BingoGetUserLatestData_20240227: {
      providesTags: [GRAPHQL_TAG_TYPE.LATEST_ELECTRICITY_DATA],
    },
    BingoGetUserElectricUsageSpecificDate_20240227: {
      providesTags: [GRAPHQL_TAG_TYPE.ELECTRICITY_USAGE_SPECIFIC_DATE],
    },
    BingoGetUserElectricUsageSpecificDatePattern5_20240227: {
      providesTags: [
        GRAPHQL_TAG_TYPE.ELECTRICITY_USAGE_SPECIFIC_DATE_PATTERN_5,
      ],
    },
    GetConsentApplicationInfoForBingo_20240227: {
      providesTags: [GRAPHQL_TAG_TYPE.CONSENT_APPLICATION_INFO_FOR_BINGO],
    },
    BingoGetLastUserElectricityData_20240227: {
      providesTags: [GRAPHQL_TAG_TYPE.LAST_USER_ELECTRICITY_DATA],
    },
  },
});
// 関数名からyyyyMMddを取り除いてexport
export const {
  useGetUserActionsByAuth0UserId_20240227Query:
    useGetUserActionsByAuth0UserIdQuery,
  useGetActionByAuth0UserIdAndActionId_20240227Query:
    useGetActionByAuth0UserIdAndActionIdQuery,
  useInsertUserAction_20240227Mutation: useInsertUserActionMutation,
  useGetNow_20240227Query: useGetNowQuery,
  useBingoGetUserMunicipality_20240227Query: useBingoGetUserMunicipalityQuery,
  useBingoInsertUser_20240227Mutation: useBingoInsertUserMutation,
  useInsertBingoUser_20240227Mutation: useInsertBingoUserMutation,
  useBingoGetUserElectricUsagePerDate_20240227Query:
    useBingoGetUserElectricUsagePerDateQuery,
  useBingoGetUserElectricUsageDetail_20240227Query:
    useBingoGetUserElectricUsageDetailQuery,
  useBingoGetAreaTemperatureByAreaAndDate_20240227Query:
    useBingoGetAreaTemperatureByAreaAndDateQuery,
  useBingoGetMyData_20240227Query: useBingoGetMyDataQuery,
  useBingoGetUserLatestData_20240227Query: useBingoGetUserLatestDataQuery,
  useBingoGetUserElectricUsageSpecificDate_20240227Query:
    useBingoGetUserElectricUsageSpecificDateQuery,
  useBingoGetUserElectricUsageSpecificDatePattern5_20240227Query:
    useBingoGetUserElectricUsageSpecificDatePattern5Query,
  useGetConsentApplicationInfoForBingo_20240227Query:
    useGetConsentApplicationInfoForBingoQuery,
  useBingoGetLastUserElectricityData_20240227Query:
    useBingoGetLastUserElectricityDataQuery,
} = api;
// 型定義からyyyyMMddを取り除く
import {
  BingoGetUserMunicipality_20240227Query,
  GetUserActionsByAuth0UserId_20240227Query,
  GetNow_20240227Query,
  BingoGetUserElectricUsageDetail_20240227Query,
  BingoGetUserElectricUsagePerDate_20240227QueryVariables,
  BingoGetUserElectricUsagePerDate_20240227Query,
  BingoGetAreaTemperatureByAreaAndDate_20240227QueryVariables,
  BingoGetAreaTemperatureByAreaAndDate_20240227Query,
  BingoGetUserElectricUsageSpecificDate_20240227QueryVariables,
  BingoGetUserElectricUsageSpecificDatePattern5_20240227QueryVariables,
} from "./generated";
export type BingoGetUserMunicipalityQuery =
  BingoGetUserMunicipality_20240227Query;
export type GetUserActionsByAuth0UserIdQuery =
  GetUserActionsByAuth0UserId_20240227Query;
export type GetNowQuery = GetNow_20240227Query;
export type BingoGetUserElectricUsageDetailQuery =
  BingoGetUserElectricUsageDetail_20240227Query;
export type BingoGetUserElectricUsagePerDateQueryVariables =
  BingoGetUserElectricUsagePerDate_20240227QueryVariables;
export type BingoGetUserElectricUsagePerDateQuery =
  BingoGetUserElectricUsagePerDate_20240227Query;
export type BingoGetAreaTemperatureByAreaAndDateQueryVariables =
  BingoGetAreaTemperatureByAreaAndDate_20240227QueryVariables;
export type BingoGetAreaTemperatureByAreaAndDateQuery =
  BingoGetAreaTemperatureByAreaAndDate_20240227Query;
export type BingoGetUserElectricUsageSpecificDateQueryVariables =
  BingoGetUserElectricUsageSpecificDate_20240227QueryVariables;
export type BingoGetUserElectricUsageSpecificDatePattern5QueryVariables =
  BingoGetUserElectricUsageSpecificDatePattern5_20240227QueryVariables;
