/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { FC } from "react";

type Props = {
  imgSrc: string;
  altText: string;
  height: number;
};

export const MainImage: FC<Props> = ({ imgSrc, altText, height }) => {
  return <img src={imgSrc} alt={altText} className={imgStyle(height)} />;
};

const imgStyle = (height: number) => css`
  height: ${height}px;
`;
