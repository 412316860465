import { css } from "@emotion/css";
import { FC } from "react";
import { Tutorial as TutorialOrganism, Props as OrganismProps } from "../../organisms/tutorial/Tutorial";

export type Props = OrganismProps

export const Tutorial: FC<Props> = (props) => {
  return (
    <div className={mainStyle}>
      <TutorialOrganism {...props}/>
    </div>
  );
};

const mainStyle = css`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: #FFFFFF;
`