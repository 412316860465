/* eslint-disable @next/next/no-img-element */
import { FC } from "react";
import { getImgSrc } from "../../../utils/municipality";

type Props = {
  closeModal: Function;
};

const altText = "CloseImage";

export const CloseButton: FC<Props> = ({closeModal}) => {
  return (
    <div onClick={() => closeModal()}>
      <img 
      style={{cursor: "pointer"}}
      src={getImgSrc("BingoTopModal-Close")}
      alt={altText} width={24} height={24} />
    </div>
  );
};
