import { useEffect } from "react";

const useOutsideClick = (
  ref: React.MutableRefObject<null> | React.MutableRefObject<HTMLDivElement>,
  handler: (event: React.BaseSyntheticEvent | MouseEvent) => void
) => {
  useEffect(() => {
    const listener = (event: React.BaseSyntheticEvent | MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export default useOutsideClick;
