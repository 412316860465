/* eslint-disable @next/next/no-img-element */
import { FC } from "react";
import { getImgSrc } from "../../../utils/municipality";

export enum page {
  onPage = 0,
  offPage = 1,
}

export type Props = {
  paging: page;
};

type ImgProp = {
  src: string;
  alt: string;
};

const imgProps: Array<ImgProp> = [
  { src: getImgSrc("Tutorial-OnPage"), alt: "onPage" },
  { src: getImgSrc("Tutorial-OffPage"), alt: "offPage" },
];

export const BingoCardPagingIcon: FC<Props> = ({ paging }) => {
  return (
    <img
      src={imgProps[paging].src}
      alt={imgProps[paging].alt}
      height={10}
      width={10}
    />
  );
};
