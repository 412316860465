import { css } from "@emotion/css";
import { FC } from "react";
import { BingoCardPagingIcon, page } from "../../atoms/BingoCardPagingIcon";

type Props = {
  pagesNum?: number;
  current: number;
};

export const BingoCardPaging: FC<Props> = ({ current, pagesNum = 3 }) => {
  let pagingArray: Array<JSX.Element> = [];
  if (pagesNum > 1) {
    for (let i = 0; i < pagesNum; i++) {
      const icon = (
        <BingoCardPagingIcon
          paging={i + 1 === current ? page.onPage : page.offPage}
          key={i}
        />
      );
      pagingArray.push(icon);
    }
  }
  const width = createWidth(pagesNum);
  return <nav className={boxStyle(width)}>{pagingArray}</nav>;
};

const createWidth = (pagesNum: number) => `${((10 + 16) * pagesNum + 10)}px`

const boxStyle = (width: string) => css`
    display: flex;
    justify-content: space-between;
    width: ${width};
    height: 10px;
    margin-left: auto;
    margin-right: auto;
`;

