import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";

type Props = {
  title: string;
};

export const HeaderMenuTitle: FC<Props> = ({ title = "" }) => {
  return (
    <div className={boxStyle}>
      <span className={titleStyle}>{title}</span>
    </div>
  );
};

const boxStyle = css`
  width: 295px;
  height: 40px;
  padding-top: 10px;
  padding-left: 16px;
  background-color: #f4f7f7;
  border-bottom: 1px solid #eaeaea;
  ${mediaQuery.portrait} {
    width: 336px;
    height: 48px;
    padding-top: 12px;
    padding-left: 40px;
    background-color: #f4f7f7;
    border-bottom: 1px solid #eaeaea;
  }
  ${mediaQuery.landscape} {
    width: 336px;
    height: 48px;
    padding-top: 12px;
    padding-left: 40px;
    background-color: #f4f7f7;
    border-bottom: 1px solid #eaeaea;
  }
`;

const titleStyle = css`
  height: 17px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #333;
  white-space: pre-wrap;
  ${mediaQuery.portrait} {
    height: 21px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #333;
    white-space: pre-wrap;
  }
  ${mediaQuery.landscape} {
    height: 21px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #333;
    white-space: pre-wrap;
  }
`;
