/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";
import { getImgSrc } from "../../../utils/municipality";

type Props = {
  text: string;
  onClick: () => void;
};
export const HeaderMenuText: FC<Props> = ({ text = "", onClick }) => {
  return (
    <div className={boxStyle} onClick={onClick}>
      <span className={textStyle}>{text}</span>
      <img
        className={imgStyle}
        src={`${getImgSrc("HeaderMenu-MoreActionNext")}`}
        alt="moreActionNext"
        width={6}
        height={12}
      />
    </div>
  );
};

const boxStyle = css`
  width: 295px;
  height: 56px;
  padding-top: 19px;
  padding-left: 16px;
  padding-right: 17px;
  padding-bottom: 17px;
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  ${mediaQuery.portrait} {
    width: 336px;
    height: 64px;
    padding-top: 22px;
    padding-left: 40px;
    padding-right: 17px;
    padding-bottom: 17px;
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  ${mediaQuery.landscape} {
    width: 336px;
    height: 64px;
    padding-top: 22px;
    padding-left: 40px;
    padding-right: 17px;
    padding-bottom: 17px;
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
`;

const textStyle = css`
  height: 17px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #333;
  white-space: pre-wrap;
  ${mediaQuery.portrait} {
    height: 21px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #333;
    white-space: pre-wrap;
  }
  ${mediaQuery.landscape} {
    height: 21px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #333;
    white-space: pre-wrap;
  }
`;

const imgStyle = css`
  margin-top: 4px;
  ${mediaQuery.portrait} {
    margin-top: 4px;
  }
  ${mediaQuery.landscape} {
    margin-top: 4px;
  }
`;
