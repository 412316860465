/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { FC, useCallback, useState } from "react";
import { useRouter } from "next/router";
import { mediaQuery } from "../../../utils/ui";
import { HeaderMenu } from "../../organisms/HeaderMenu";
import { openCommonApp, path } from "../../../utils/ui/route";
import * as style from "./style";
import { ElectricityConnectionModal } from "../ElectricityConnectionModal";
import { getHtmlSrc, getImgSrc, getUrl } from "../../../utils/municipality";

export const headerHeight = {
  sp: "48px",
  spNum: 48,
  portrait: 48,
  landscapNum: 56,
  landscape: "56px",
} as const;

export type Props = {
  menuHandlers: MenuHandlers;
};

export type MenuHandlers = {
  isElectricityConnectionModalVisible: boolean;
  homeHandler: () => void;
  actionTopHandler: () => void;
  powerHandler: () => void;
  onCloseElectricityConnectionModal: () => void;
};

export const Header: FC<Props> = ({ menuHandlers }) => {
  const router = useRouter();
  // ヘッダーリスト表示切替のためのstate
  const [isOpenMenu, setOpenMenu] = useState(false);
  // 見えるグリーンについて画面表示切替のためのstate
  const [isVisibleGreen, setVisibleGreen] = useState(false);
  const onComplete = useCallback(() => {
    return setOpenMenu(false), setVisibleGreen(false);
  }, [setVisibleGreen]);
  const imageParams = process.env.NEXT_PUBLIC_IMAGE_PARAMETERS;

  // お問い合わせ先へ遷移
  const contactUsHandler = useCallback(() => {
    if (location.pathname === path.contactUs) {
      setOpenMenu(false);
    } else {
      router.push(path.contactUs);
    }
  }, [router]);

  // // ホーム画面への追加方法へ遷移
  // const howToAddHandler = useCallback(() => {
  //   if (location.pathname === path.addHome) {
  //     setOpenMenu(false);
  //   } else {
  //     router.push(path.addHome);
  //   }
  // }, [router]);

  // みえるグリーンについてへ遷移
  const aboutVisibleGreenHandler = useCallback(() => {
    setVisibleGreen(true);
  }, []);

  // FAQについてへ遷移
  const faqHandler = useCallback(() => {
    setOpenMenu(false);
    window.open(getUrl("Other-HamburgerMenu-FaqLink"), "_blank");
  }, []);

  // ログアウトへ遷移
  const logoutHandler = useCallback(() => {
    // console.log(location.pathname);
    if (location.pathname === path.logoutConfirm) {
      setOpenMenu(false);
    } else {
      router.push(path.logoutConfirm);
    }
  }, [router]);

  // 電力データ連携関連情報へ遷移
  const powerLinkageInfoHandler = useCallback(() => {
    setOpenMenu(false);
    openCommonApp("/powerLinkageInfo/powerLinkageInfo");
  }, []);

  // 利用規約へ遷移
  const termsOfServiceHandler = useCallback(() => {
    setOpenMenu(false);
    window.open(getHtmlSrc("Other-HamburgerMenu-TermsLink"), "_blank");
  }, []);

  // プライバシーポリシーへ遷移
  const privacyPolicyHandler = useCallback(() => {
    setOpenMenu(false);
    window.open(getUrl("Other-HamburgerMenu-PrivacyPolicyLink"), "_blank");
  }, []);

  return (
    <header>
      <div className={style.root}>
        <img
          src={`${getImgSrc("Header-Menu")}`}
          alt="メニュー"
          width="32"
          height="37"
          onClick={() => setOpenMenu(true)}
        />
        <div className={style.space}></div>
        <img
          src={`${getImgSrc("Header-HeaderPower")}`}
          alt="電力量"
          width="40"
          height="37"
          onClick={menuHandlers.powerHandler}
        />
        <img
          src={`${getImgSrc("Header-Home")}`}
          alt="ホーム"
          width="40"
          height="37"
          className={style.itemMargin}
          onClick={menuHandlers.homeHandler}
        />
        <img
          src={`${getImgSrc("Header-Action")}`}
          alt="アクション"
          width="40"
          height="37"
          className={style.itemMargin}
          onClick={menuHandlers.actionTopHandler}
        />
      </div>
      {isOpenMenu && (
        <div
          className={inactiveStyle}
          onClick={() => {
            setOpenMenu(false);
          }}
        ></div>
      )}
      <div className={menuStyle}>
        {isOpenMenu && (
          <HeaderMenu
            contactUsHandler={contactUsHandler}
            faqHandler={faqHandler}
            // howToAddHandler={howToAddHandler}
            aboutVisibleGreenHandler={aboutVisibleGreenHandler}
            logoutHandler={logoutHandler}
            isVisibleGreenStatus={isVisibleGreen}
            onComplete={onComplete}
            powerLinkageInfoHandler={powerLinkageInfoHandler}
            termsOfServiceHandler={termsOfServiceHandler}
            privacyPolicyHandler={privacyPolicyHandler}
          />
        )}
      </div>
      {menuHandlers.isElectricityConnectionModalVisible && (
        <ElectricityConnectionModal
          closeHandler={menuHandlers.onCloseElectricityConnectionModal}
        />
      )}
    </header>
  );
};

const boxStyle = css`
  /* height: 100%; */
  width: 100vw;
  height: ${headerHeight.sp};
  position: relative;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 16px;
  padding-right: 13px;
  /* background-color: #fff; */
  display: flex;
  justify-content: space-between;
  ${mediaQuery.portrait} {
    width: 100vw;
    height: ${headerHeight.portrait};
    position: relative;
    padding-top: initial;
    padding-bottom: initial;
    padding-left: initial;
    padding-right: initial;
    padding: 6px 30px;
    display: flex;
    justify-content: space-between;
  }
  ${mediaQuery.landscape} {
    width: 100vw;
    height: ${headerHeight.landscape};
    position: relative;
    padding-top: initial;
    padding-bottom: initial;
    padding-left: initial;
    padding-right: initial;
    padding: 6px 30px;
    /* background-color: #fff; */
    display: flex;
    justify-content: space-between;
  }
`;

const menuStyle = css`
  top: 0;
  left: 0;
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  ${mediaQuery.portrait} {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: space-between;
  }
  ${mediaQuery.landscape} {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: space-between;
  }
`;

const inactiveStyle = css`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  ${mediaQuery.portrait} {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
  }
  ${mediaQuery.landscape} {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;
