const IS_LOGOUT_AT_SENDMAIL = "GreenApp-Logout-At-Sendmail";
/**
 * メール送信完了画面から遷移してきた場合、trueを返却する
 * @returns
 */
export const isLogoutAtSendmail = (): boolean => {
  if (typeof window !== "undefined" && window.sessionStorage) {
    return !!window.sessionStorage.getItem(IS_LOGOUT_AT_SENDMAIL);
  }
  return true;
};
/**
 * メール送信完了画面から遷移したことを記録する
 */
export const saveLogoutAtSendmail = () => {
  if (typeof window !== "undefined" && window.sessionStorage)
    window.sessionStorage.setItem(
      IS_LOGOUT_AT_SENDMAIL,
      "" + new Date().getTime()
    );
};
/**
 * メール送信完了画面から遷移した記録を削除する
 */
export const clearLogoutAtSendmail = () => {
  if (typeof window !== "undefined" && window.sessionStorage)
    window.sessionStorage.removeItem(IS_LOGOUT_AT_SENDMAIL);
};
