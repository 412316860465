import { getTutorialPages,} from "../../municipality";

export const tutorialArray = getTutorialPages();

export const labels = {
  completeButton: "がんばろう！",
};

const IS_DONE_TUTORIAL_KEY = "GreenApp-Winter-Tutorial-Done";
/**
 * チュートリアル実施済の場合、trueを返却する
 * @returns
 */
export const isDoneTutorial = (): boolean => {
  if (typeof window !== "undefined" && window.localStorage) {
    return !!window.localStorage.getItem(IS_DONE_TUTORIAL_KEY);
  }
  return true;
};
/**
 * チュートリアル実施済を保存する
 */
export const saveDoneTutorial = () => {
  if (typeof window !== "undefined" && window.localStorage)
    window.localStorage.setItem(
      IS_DONE_TUTORIAL_KEY,
      "" + new Date().getTime()
    );
};
