import { css } from "@emotion/css";
import { FC } from "react";
import { MoreActionNext } from "../../../atoms/MoreActionNext";
import { getOverallColor } from "../../../../utils/municipality";

type Props = {
  onClick: () => void;
};

export const Footer: FC<Props> = (props) => {
  return (
    <nav onClick={() => props.onClick()}>
      <span className={textStyle}>スキップ</span>
      <MoreActionNext />
    </nav>
  );
};

const textStyle = css`
  margin-right: 8px;
  font-size: 18px;
  font-weight: 600;
  color: ${getOverallColor("subColor")};
  cursor: pointer;
`;
