import { css } from "@emotion/css";

export const root = css`
  display: flex;
  width: 100vw;
  height: 51px;
  padding: 0 16px 3px;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 0px 0px 8px 8px;
`;

export const itemMargin = css`
  margin-left: 5px;
`;

export const space = css`
  flex: 1;
`;
