import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../../utils/ui";

type Props = {
  message: string;
};

export const Text: FC<Props> = ({ message }) => {
  return <span className={textStyle}>{message}</span>;
};

const textStyle = css`
  font-size: 18px;
  font-weight: 600;
  white-space: pre-line;
  letter-spacing: -1px;
  ${mediaQuery.landscape} {
    font-size: 20px;
    font-weight: 600;
  }
`;
