/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { FC } from "react";
import { motion } from "framer-motion";
import { mediaQuery } from "../../../utils/ui";
import { HeaderMenuTitle } from "../../atoms/HeaderMenuTitle";
import { HeaderMenuText } from "../../atoms/HeaderMenuText";
import { Tutorial } from "../../../components/templates/Tutorial";
import { getLabel } from "../../../utils/municipality";

type Props = {
  contactUsHandler: () => void;
  faqHandler: () => void;
  // howToAddHandler: () => void;
  aboutVisibleGreenHandler: () => void;
  logoutHandler: () => void;
  isVisibleGreenStatus: boolean;
  onComplete: () => void;
  powerLinkageInfoHandler: () => void;
  termsOfServiceHandler: () => void;
  privacyPolicyHandler: () => void;
};

export const HeaderMenu: FC<Props> = (props) => {
  return (
    <>
      {props.isVisibleGreenStatus && <Tutorial onComplete={props.onComplete} />}
      {props.isVisibleGreenStatus === false && (
        <motion.div
          initial={{ x: -350 }}
          animate={{ x: 0 }}
          transition={{
            delay: 0.2,
            bounce: false,
            default: { ease: "linear" },
          }}
        >
          <section className={boxStyle}>
            <HeaderMenuTitle title={"お問い合わせ"} />
            <HeaderMenuText
              text={"お問い合わせ先"}
              onClick={props.contactUsHandler}
            />
            <HeaderMenuText text={"FAQ"} onClick={props.faqHandler} />
            {/* <HeaderMenuTitle title={"設定"} />
            <HeaderMenuText
              text={"ホーム画面への追加方法"}
              onClick={props.howToAddHandler}
            /> */}
            <HeaderMenuTitle title={"その他"} />
            <HeaderMenuText
              text={"このサービスについて"}
              onClick={props.aboutVisibleGreenHandler}
            />
            <HeaderMenuText
              text="電力データ連携関連情報"
              onClick={props.powerLinkageInfoHandler}
            />
            <HeaderMenuText
              text={"利用規約"}
              onClick={props.termsOfServiceHandler}
            />
            <HeaderMenuText
              text={getLabel("Other-HamburgerMenu-PrivacyPolicyText")}
              onClick={props.privacyPolicyHandler}
            />
            <HeaderMenuText text={"ログアウト"} onClick={props.logoutHandler} />
            <span className={creditStyle}>{"Powered by GDBL Corp."}</span>
          </section>
        </motion.div>
      )}
    </>
  );
};

const boxStyle = css`
  width: 295px;
  height: 100vh;
  position: relative;
  background-color: #fff;
  z-index: 15;
  ${mediaQuery.portrait} {
    width: 336px;
    height: 100vh;
    position: relative;
    background-color: #fff;
    z-index: 15;
  }
  ${mediaQuery.landscape} {
    width: 336px;
    height: 100vh;
    position: relative;
    background-color: #fff;
    z-index: 15;
  }
`;

const creditStyle = css`
  margin-left: 16px;
  margin-bottom: 24px;
  font-size: 12px;
  font-weight: 300;
  position: absolute;
  bottom: 0;
  color: #868686;
  ${mediaQuery.portrait} {
    margin-left: 40px;
    margin-bottom: 23px;
    font-size: 14px;
    font-weight: 300;
    position: absolute;
    bottom: 0;
    color: #868686;
  }
  ${mediaQuery.landscape} {
    margin-left: 40px;
    margin-bottom: 23px;
    font-size: 14px;
    font-weight: 300;
    position: absolute;
    bottom: 0;
    color: #868686;
  }
`;
