import {
  GetTokenSilentlyOptions,
  RedirectLoginOptions,
  useAuth0,
  User,
} from "@auth0/auth0-react";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { useAuth0State } from "../../services/auth0/selectors";
import { setAccessToken, setUserMetadata } from "../../services/auth0/slice";
// import { UserInfoResponse } from "../../services/auth0/types";
// import { useUserInfoQuery } from "../../services/auth0/userInfo";
import { client } from "../../services/graphql";
import { useAppDispatch } from "../../services/store";
import { path } from "../ui/route";
import { isDoneTutorial } from "../ui/tutorial";
import { clearLogoutAtSendmail, isLogoutAtSendmail } from "../ui/sendMail";
import {
  useBingoGetUserMunicipalityQuery,
  useBingoInsertUserMutation,
} from "../../services/graphql/enhanceApi";
import { BingoGetUserMunicipalityQuery } from "../../services/graphql/enhanceApi";
import { getMunicipalityName } from "../municipality";

export type GreenActionAuth0 = {
  isLoading: boolean; // Auth0ライブラリが初期化中の場合、true
  isError: boolean; // Auth0処理でエラーが発生した場合、true エラー内容の返却が必要になった場合は、要修正
  isAuthenticated: boolean; // Auth0認証処理が完了している場合、true
  isAccessTokenReady: boolean; // Auth0認証処理が完了して、access_tokenの取得処理が完了している場合、true
  auth0UserId?: string; // Auth0でユーザーを識別するID
  userMetadata?: UserMetadata; // Auth0で保持しているユーザー情報
  logoutFunction: Function;
  updateAccessToken: () => Promise<void>;
  error?: Error;
  userData?: UserData;
};

export type UserMetadata = {
  name: string;
  municipalities?: string;
  school?: string;
  grade?: string;
  email?: string;
};

export type ConsentApplicationStatus =
  | "yet"
  | "processing"
  | "done"
  | "resultReceived"
  | "cancelByUser"
  | "cancelByTransfer";

export type UserData = {
  consentApplicationStatus: ConsentApplicationStatus;
  prefCode?: string | null;
};

const USERS_CREATED_BY = "bingo-tokyo";
// const USER_METADATA_KEY =
//   process.env.NEXT_PUBLIC_AUTH0_USER_METADATA_PREFIX + "user_metadata";
const AUTHORIZATION_HEADER = "Authorization";
const AUTHORIZATION_PREFIX = "Bearer ";
const isPreparing = process.env.NEXT_PUBLIC_IS_PREPARING === "true" || false;

type AuthStatus =
  | "init" // 初期状態
  | "error" // 処理中にエラーが発生した
  | "isNotDoneTutorial" // チュートリアル未実施
  | "gettingAccessToken" // アクセストークン取得中
  | "notLogin" // 未ログイン
  | "accessTokenReady" // アクセストークン取得済（認証完了）
  // | "gettingUserInfo" // ユーザー情報再取得中
  | "userInfoReady" // ユーザー情報設定済
  | "isNotRegisteredUserInfo"; // ユーザー情報未設定状態

export const useGreenActionAuth0 = (
  isAuth0CacheIgnore = false
): GreenActionAuth0 => {
  const {
    error,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    user,
    logout,
  } = useAuth0();
  const dispatch = useAppDispatch();
  const { registedUserMetadata, isRequiredCreatingAccount } = useAuth0State();
  const router = useRouter();
  const [
    {
      authStatus,
      auth0UserId,
      userMetadata,
      accessToken,
      authError,
      logoutAtSendmail,
      userData,
    },
    setAuthState,
  ] = useState<{
    authStatus: AuthStatus;
    auth0UserId?: string;
    userMetadata?: UserMetadata;
    accessToken?: string; // 各画面ごとにアクセストークンを更新するために、ローカルのstateに取得したアクセストークンを保持する
    authError?: Error;
    logoutAtSendmail?: boolean; // 未ログイン状態の場合、直前のログアウトがメール送信完了画面かを判定するフラグ
    userData?: UserData;
  }>({ authStatus: "init" });

  const [insertUser] = useBingoInsertUserMutation();
  /**
   * アクセストークン取得関数
   */
  const updateAccessToken = useCallback(async () => {
    try {
      // console.log(await getIdTokenClaims());
      // アクセストークン取得リクエスト送信
      const options: GetTokenSilentlyOptions = isAuth0CacheIgnore
        ? {
            cacheMode: "off",
          }
        : {};
      const token = await getAccessTokenSilently(options);
      if (token !== accessToken) {
        // graphql向けにaccess_tokenを設定
        client.setHeader(
          AUTHORIZATION_HEADER,
          `${AUTHORIZATION_PREFIX}${token}`
        );
        // console.log("xxxxxxxxxxxxxxxxxxxxx" + token);
        // アプリ全体で参照するためにアクセストークンを保存
        dispatch(setAccessToken(token));
        setAuthState({ authStatus: "accessTokenReady", accessToken: token });
      }
    } catch (e) {
      console.error(e);
      if (isAuth0LoginRequired(e)) {
        setAuthState({
          authStatus: "notLogin",
          logoutAtSendmail: isLogoutAtSendmail(),
        });
      } else {
        const authError =
          e instanceof Error
            ? e
            : new Error(
                "認証処理でエラーが発生しました。再度ログインしてから処理を行ってください。"
              );
        setAuthState({ authStatus: "error", authError });
      }
    }
  }, [dispatch, getAccessTokenSilently, accessToken, isAuth0CacheIgnore]);

  /**
   * 認証状態の管理
   */
  useEffect(() => {
    if (authStatus === "init" && error) {
      setAuthState({ authStatus: "error" });
    } else if (authStatus === "init" && !isLoading) {
      if (!isDoneTutorial()) {
        // チュートリアル未実施
        setAuthState({ authStatus: "isNotDoneTutorial" });
      } else {
        // チュートリアル実施済み
        // アクセストークン取得
        setAuthState({ authStatus: "gettingAccessToken" });
        (async () => {
          await updateAccessToken();
        })();
      }
      // } else if (authStatus === "accessTokenReady") {
      //   if (user && user.sub && !isAuth0UserInit(user)) {
      //     // ユーザー情報が保存されていない
      //     if (registedUserMetadata) {
      //       // 直前に登録されたユーザー情報を保持している場合
      //       setAuthState({
      //         authStatus: "userInfoReady",
      //         accessToken,
      //         auth0UserId: user.sub,
      //         userMetadata: { ...registedUserMetadata, email: user.email },
      //       });
      //     } else {
      //       // ユーザー情報の再取得を実施する
      //       setAuthState({
      //         authStatus: "gettingUserInfo",
      //         accessToken,
      //         auth0UserId: user.sub,
      //       });
      //     }
      //   } else if (user && user.sub && isAuth0UserInit(user)) {
      //     // ユーザー情報が保存されている
      //     setAuthState({
      //       authStatus: "userInfoReady",
      //       accessToken,
      //       auth0UserId: user.sub,
      //       userMetadata: createUserMetadata(user),
      //     });
      //   }
    }
  }, [
    error,
    isLoading,
    // user,
    authStatus,
    // accessToken,
    // registedUserMetadata,
    updateAccessToken,
  ]);

  /**
   * Auth0のデータにユーザー情報が設定されていない場合
   * かつ、ユーザー情報登録画面の入力値も保存されていない場合
   * かつ、アクセストークンが取得済みの場合
   * Auth0からユーザー情報を再取得する
   * →　アカウント登録、ユーザー情報登録後にAuth0にID Tokenに登録したユーザー情報が反映されていない状態
   */
  const {
    data: userInfoResponse,
    isSuccess,
    error: userInfoQueryError,
  } = useBingoGetUserMunicipalityQuery(
    { auth0_user_id: user?.sub || "", municipality: getMunicipalityName() },
    {
      skip: !user || !user.sub || authStatus !== "accessTokenReady",
      // !user || isAuth0UserInit(user) || !!registedUserMetadata || !accessToken,
    }
  );
  /**
   * useUserInfoQueryの結果を判定する
   */
  useEffect(() => {
    // console.log("authStatus", authStatus);
    if (
      user?.sub &&
      authStatus === "accessTokenReady" &&
      isSuccess &&
      userInfoResponse
    ) {
      if (
        !userInfoResponse.zerocame_users ||
        userInfoResponse.zerocame_users.length < 1
      ) {
        // usersテーブルにレコードが存在しない
        insertUser({
          auth0_user_id: user.sub,
          created_by: USERS_CREATED_BY,
        }).catch((err) => {
          console.error(err);
          setAuthState({ authStatus: "error", authError: err });
        });
      } else if (userInfoResponse.zerocame_users[0].bingo_users.length > 0) {
        // usersテーブル、bingo_usersテーブルにレコードが存在する
        const userMetadata = createUserMetadataFromQueryResult(
          userInfoResponse,
          user?.email || ""
        );
        setAuthState({
          authStatus: "userInfoReady",
          accessToken,
          auth0UserId: user.sub,
          userMetadata: userMetadata,
          userData: {
            consentApplicationStatus: checkConsentApplicationStatus(
              userInfoResponse.zerocame_users[0]
                .consent_application_zeroca_status
            ),
            prefCode: userInfoResponse.zerocame_users[0].pref_code,
          },
        });
        // 取得した値を保持して処理を継続する
        dispatch(setUserMetadata(userMetadata));
      } else {
        // bingo_usersテーブルにレコードが存在しない
        setAuthState({
          authStatus: "isNotRegisteredUserInfo",
          accessToken,
          auth0UserId: user.sub,
          userData: {
            consentApplicationStatus: checkConsentApplicationStatus(
              userInfoResponse.zerocame_users[0]
                .consent_application_zeroca_status
            ),
            prefCode: userInfoResponse.zerocame_users[0].pref_code,
          },
        });
      }
    }
  }, [
    authStatus,
    accessToken,
    isSuccess,
    userInfoResponse,
    dispatch,
    user?.email,
    user?.sub,
    insertUser,
  ]);

  if (authStatus === "init") {
    // Auth0ライブラリ初期化中
    return createResponse(
      isLoading,
      false,
      false,
      false,
      logout,
      updateAccessToken
    );
  } else if (authStatus === "error") {
    // Auth0ライブラリ処理でエラーが発生
    return createResponse(
      isLoading,
      true,
      isAuthenticated,
      false,
      logout,
      updateAccessToken,
      undefined,
      undefined,
      undefined,
      error ? error : authError
    );
  }

  /**
   * 画面遷移が発生する場合
   */
  if (authStatus === "isNotDoneTutorial") {
    router.push(path.tutorial);
  } else if (authStatus === "notLogin") {
    console.log(logoutAtSendmail);
    // Auth0未ログイン状態
    const loginOptions: RedirectLoginOptions =
      isRequiredCreatingAccount || logoutAtSendmail
        ? {
            authorizationParams: {
              initialScreen: "signUp",
              screen_hint: "signUp",
            },
          }
        : {};
    // 直前のログアウトがメール送信完了画面であることを保持しているフラグをクリアする
    if (logoutAtSendmail) clearLogoutAtSendmail();
    loginWithRedirect(loginOptions);
  } else if (authStatus === "isNotRegisteredUserInfo") {
    // ユーザー情報登録画面以外からの呼び出しで、
    // Auth0のユーザー情報を再取得してもユーザー情報が含まれていなかった場合
    if (!isAuth0CacheIgnore) router.push(path.accountRegist);
  } else if (isPreparing && authStatus === "userInfoReady") {
    // 準備中期間かつユーザー情報登録画面以外の呼び出しで、
    // ユーザー情報取得済みであれば、準備中画面へ
    if (!isAuth0CacheIgnore) router.push(path.ready);
  }

  const logoutFunction = () => {
    router.push(path.logout);
  };
  console.log("authStatus", authStatus);
  return createResponse(
    false, // isLoading
    false, // isError
    authStatus === "userInfoReady" || authStatus === "isNotRegisteredUserInfo",
    !!accessToken,
    logoutFunction,
    updateAccessToken,
    auth0UserId,
    userMetadata,
    userData
  );
};

const createResponse = (
  isLoading: boolean,
  isError: boolean,
  isAuthenticated: boolean,
  isAccessTokenReady: boolean,
  logoutFunction: Function,
  updateAccessToken: () => Promise<void>,
  auth0UserId?: string,
  userMetadata?: UserMetadata,
  userData?: UserData,
  error?: Error
): GreenActionAuth0 => {
  return {
    isLoading,
    isError,
    isAuthenticated,
    isAccessTokenReady,
    logoutFunction,
    auth0UserId,
    userMetadata,
    updateAccessToken,
    userData,
    error,
  };
};

/**
 * ユーザー情報の初期登録が完了している場合、trueを返却する
 * @param user
 */
// const isAuth0UserInit = (user: User): boolean => {
//   // TODO:姓名がAuth0から取得できないため一定の値にしている
//   // if (user.family_name && user.given_name) {
//   //   return true;
//   // }
//   // return false;
//   return false;
// };

// const isAuth0userInfoInit = (userInfo: UserInfoResponse) => {
//   // TODO:姓名がAuth0から取得できないため一定の値にしている
//   // if (userInfo.family_name && userInfo.given_name) {
//   //   return true;
//   // }
//   // return false;
//   return false;
// };

// const createUserMetadata = (user: User): UserMetadata => {
//   return {
//     // TODO:姓名がAuth0から取得できないためコメントアウト
//     // family_name: user.family_name || "",
//     // given_name: user.given_name || "",
//     // municipalities: user[USER_METADATA_KEY]["municipalities"] || "",
//     // school: user[USER_METADATA_KEY]["school"] || "",
//     // grade: user[USER_METADATA_KEY]["grade"] || "",
//     email: user.email || "",
//   };
// };

const createUserMetadataFromQueryResult = (
  queryResult: BingoGetUserMunicipalityQuery,
  email: string
): UserMetadata => {
  const bingoUser = queryResult.zerocame_users[0]?.bingo_users[0];
  return {
    name: bingoUser.name || "",
    municipalities: bingoUser.municipalities
      ? bingoUser.municipalities
      : undefined,
    school: bingoUser.school ? bingoUser.school : undefined,
    grade: bingoUser.grade ? bingoUser.grade : undefined,
    email,
  };
};

const auth0ErrorMessagesNotLogin = [
  "Login required",
  "External interaction required",
];
/**
 * getAccessTokenSilentlyで返却されたエラーが
 * ログインを要求するものか判定する
 * @param e
 * @returns ログインが要求されている場合、true
 */
const isAuth0LoginRequired = (e: unknown): boolean => {
  const isLoginRequired = auth0ErrorMessagesNotLogin.some(
    (errorMessage) =>
      (e instanceof Error && e.message.includes(errorMessage)) ||
      (typeof e === "string" && e.includes(errorMessage))
  );

  return isLoginRequired;
};

const checkConsentApplicationStatus = (
  consentApplicationStatus?: string | null
): ConsentApplicationStatus => {
  switch (consentApplicationStatus) {
    case "1":
      return "processing";
    case "2":
      return "done";
    case "3":
      return "resultReceived";
    case "4":
      return "cancelByUser";
    case "5":
      return "cancelByTransfer";
    default:
      return "yet";
  }
};
