/* eslint-disable @next/next/no-img-element */
import { css } from "@emotion/css";
import { FC } from "react";
import { mediaQuery } from "../../../utils/ui";
import { getImgSrc } from "../../../utils/municipality";

type ImageConf = {
  src: string;
  style: string;
  width: number;
  height: number;
};

const imageStyle = css`
  width: 6px;
  height: 12px;
  display: inline;
  cursor: pointer;
  ${mediaQuery.landscape} {
    width: 6px;
    height: 12px;
    display: inline;
    cursor: pointer;
  }
`;

const altText = "もっとアクションする";
const imageSrc = getImgSrc("Tutorial-MoreActionNext");

export const MoreActionNext: FC<{}> = () => {
  const image: ImageConf = {
    src: imageSrc,
    style: imageStyle,
    width: 6,
    height: 12,
  };
  return (
    <img
      src={image.src}
      alt={altText}
      width={image.width}
      height={image.height}
      className={imageStyle}
    />
  );
};
