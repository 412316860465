import { css } from "@emotion/css";
import { FC, useCallback, useEffect, useState, useContext } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import { labels, tutorialArray } from "../../../../utils/ui/tutorial";
import { SeeDetailsButton } from "../../../atoms/seeDetailsButton";
import { MainImage } from "../../../atoms/tutorial/MainImage";
import { Text } from "../../../atoms/tutorial/Text";
import { BingoCardPaging } from "../../../molucules/BingoCardPaging";
import { Footer } from "../../../molucules/tutorial/Footer";
import { mediaQuery, DisplayContext } from "../../../../utils/ui";

import "swiper/css";

const fixedAreaHeight = 325;
const fixedAreaHeightSp = 308;
const boxWidth = 620;
const boxWidthSp = 351;
const paddingTop = 13;
const paddingTopSp = 8;

export type Props = {
  onComplete: () => void;
};

export const Tutorial: FC<Props> = ({ onComplete }) => {
  const [imgHeight, setImageHeight] = useState(0);
  const [imgWidth, setImageWidth] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isEnd, setEnd] = useState(false);

  const currentDisplay = useContext(DisplayContext);

  useEffect(() => {
    if (currentDisplay === "TAB_LANDSCAPE") {
      setImageHeight(window.innerHeight - fixedAreaHeight - paddingTop);
    } else {
      const imgAreaMaxHeight =
        window.innerHeight - fixedAreaHeightSp - paddingTopSp;

      // window幅より狭ければMaxHeight、逆であればWindow幅ベース
      if (imgAreaMaxHeight < window.innerWidth) {
        // XXX 高さはpadding左右分を減算（24px）
        setImageHeight(imgAreaMaxHeight - 24);
        setImageWidth(imgAreaMaxHeight);
      } else {
        setImageHeight(window.innerWidth - 24);
        setImageWidth(window.innerWidth);
      }
    }
  }, [setImageHeight, setImageWidth, currentDisplay]);

  useEffect(() => {
    if (currentIndex === tutorialArray.length - 1) {
      setEnd(true);
    }
  }, [currentIndex, setEnd]);
  return (
    <article className={boxStyle(imgWidth)}>
      <Swiper spaceBetween={50} allowTouchMove={false} initialSlide={0}>
        {tutorialArray.map((tutorial, i) => (
          <SwiperSlide key={i}>
            <div
              id="ga-tutorial"
              key={`tutorial-${i}`}
              className={centerizedStyle}
            >
              <MainImage
                imgSrc={`${tutorial.imgSrc}?${process.env.NEXT_PUBLIC_IMAGE_PARAMETERS}`}
                altText={tutorial.altText}
                height={imgHeight}
              />
            </div>
          </SwiperSlide>
        ))}

        <div className={fixedAreaStyle}>
          <BingoCardPaging
            pagesNum={tutorialArray.length}
            current={currentIndex + 1}
          />
          <div className={textBoxStyle}>
            <Text
              message={
                currentDisplay === "TAB_LANDSCAPE"
                  ? tutorialArray[currentIndex].message
                  : tutorialArray[currentIndex].messageSp
              }
            />
          </div>
          {!isEnd && (
            <>
              <SwiperController
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
                endIndex={tutorialArray.length - 1}
              />
              <div className={footerStyle}>
                <Footer onClick={onComplete} />
              </div>
            </>
          )}
          {isEnd && (
            <div className={centerizedStyle}>
              <SeeDetailsButton
                actionStatus="enable"
                onclick={onComplete}
                title={labels.completeButton}
              />
            </div>
          )}
        </div>
      </Swiper>
    </article>
  );
};

type ControllerProps = {
  currentIndex: number;
  setCurrentIndex: (nextIndex: number) => void;
  endIndex: number;
};

const SwiperController: FC<ControllerProps> = ({
  currentIndex,
  setCurrentIndex,
  endIndex,
}) => {
  const swiper = useSwiper();

  const slideNext = useCallback(() => {
    setCurrentIndex(currentIndex + 1);
    swiper.slideNext();
  }, [setCurrentIndex, currentIndex, swiper]);
  return (
    <div className={centerizedStyle}>
      <SeeDetailsButton
        title="次へ"
        onclick={() => slideNext()}
        actionStatus={"next"}
      />
    </div>
  );
};

const boxStyle = (imgWidth: number) => css`
  /* スマホ／タブレット縦向けに動的設定 */
  width: ${imgWidth}px;
  height: 100vh;
  padding-top: ${paddingTopSp}px;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  ${mediaQuery.landscape} {
    width: ${boxWidth}px;
    padding-top: ${paddingTop}px;
    padding-left: initial;
    padding-right: initial;
  }
`;

const fixedAreaStyle = css`
  height: ${fixedAreaHeightSp}px;
  width: ${boxWidthSp}px;
  position: fixed;
  left: calc(50% - ${boxWidthSp}px / 2);
  bottom: 0;
  padding-top: 24px;
  padding-left: 12px;
  padding-right: 12px;
  ${mediaQuery.landscape} {
    height: ${fixedAreaHeight}px;
    width: ${boxWidth}px;
    left: calc(50% - ${boxWidth}px / 2);
    padding-top: 28px;
    padding-left: initial;
    padding-right: initial;
  }
`;

const textBoxStyle = css`
  margin-top: 24px;
  margin-bottom: 18px;
  height: 82px;
  width: 100%;
  text-align: center;
  ${mediaQuery.landscape} {
    margin-top: 44px;
    margin-bottom: 40px;
    height: 60px;
  }
`;

const centerizedStyle = css`
  display: flex;
  justify-content: center;
`;

const footerStyle = css`
  margin-top: 16px;
  ${centerizedStyle};
  ${mediaQuery.landscape} {
    margin-top: 32px;
  }
`;
